import { useEffect } from 'react';

import { DEP_LITE_REGISTRATION } from 'utils/constants/urls';

const goToPage = () => {
  window.location = DEP_LITE_REGISTRATION;
};

const WillsCenter = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      goToPage();
    }
  });

  return null;
};

export default WillsCenter;
